<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="fe-row" data-test="candidate-row")
    td.fe-select
      v-checkbox(
        hide-details
        :value="isSelected"
        :key="index"
        @change="select"
      ).mt-0
    td.fe-index.pl-1
      span.pr-1 {{ positionInList }}
    td.fe-course
      span {{ item.course.date | DDMMYYYYFormatting }}
      br
      span {{ courseCityName }}
      br
      span {{ item.course.name || '' }}
    td.fe-candidate
      span {{ item.candidate.name }}
      br
      span {{ item.candidate.number || '-' }}
    td.fe-exam
      span {{ item.exam.product_name }}
      br
      span {{ item.exam.location }}
      br
      span {{ item.exam.datetime | convertToDefaultDateHourFormat }}
    td.fe-course-leaders
        span(v-for="(leader, index) in item.leaders" :key="index") {{ leader.name }}
          span(v-if="index !== (item.leaders.length - 1)") ,&nbsp;
        span(v-if="!item || !item.leaders || !item.leaders.length") -
    td.fe-rating
      span {{ item.cursus_rating || '-' }}
    td.fe-comment
      span(v-if="item.cursus_comment" v-html="item.cursus_comment")
      span(v-else) -
    td.fe-cbr-exam-rating
      span {{ item.cbr_examen_rating || '-' }}
    td.fe-cbr-exam-comment
      span(v-if="item.cbr_examen_comment" v-html="item.cbr_examen_comment")
      span(v-else) -
    td.fe-leader-rating
      span {{ item.cursusleider_rating || '-' }}
    td.fe-leader-comment
      span(v-if="item.cursusleider_comment" v-html="item.cursusleider_comment")
      span(v-else) -
    td.fe-created-at
      span {{ item.created_at | convertToDefaultDateHourFormat }}
    td.fe-status
      change-status(:ID="item.id" :value="item.task_status" @change="changeStatus")
        template(v-slot:activator="changeStatus")
          v-btn(
            @click="changeStatus.toggle"
            color="primary"
            :ripple='false'
            x-small
            dark
          ) change status
</template>

<script>
import { mapGetters } from 'vuex';
import { DDMMYYYYFormatting, convertToDefaultDateHourFormat } from '@/util'
import { REAPPLY_APPLICATIONS_TYPES_LIST } from '../core/feedback-const'

export default {
  name: 'FeedbackItem',

  props: {
    isSelected: Boolean,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    pagination: Object,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    loading: Boolean
  },

  inject: {
    updateInList: 'updateItem',
    getDefaultPageSize: 'getDefaultPageSize',
    feedbackService: 'feedbackService'
  },

  data: () => ({
    REAPPLY_APPLICATIONS_TYPES_LIST
  }),

  filters: {
    DDMMYYYYFormatting,
    convertToDefaultDateHourFormat
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size
    },

    courseCityName() {
      let city = this.cities.find(city => city.ID === this.item.course.city)
      return city.name
    }
  },

  methods: {
    updateLogs({res, task_due_date}) {
      this.updateInList(this.item.id, 'task_logs', [res, ...this.item.task_logs])
      if (task_due_date) this.updateInList(this.item.id, 'task_due_date', task_due_date)
    },

    changeStatus(value) {
      this.$emit('change:status', value)
    }
  },

  components: {
    changeStatus: () => import('./actions/ChangeStatus.vue'),
    dueDate: () => import('./FeedbackDueDate.vue'),
  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.fe-row {
  .fe- {
    &select div {
      padding-left: 12px;
    }
    &index {
      padding-left: 4px !important;
    }
  }
}
</style>

